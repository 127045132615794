import { render, staticRenderFns } from "./employee.vue?vue&type=template&id=568bae71&functional=true"
var script = {}
import style0 from "./employee.vue?vue&type=style&index=0&id=568bae71&prod&lang=scss"
import style1 from "./employee.vue?vue&type=style&index=1&id=568bae71&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports